import React from 'react';
import { Layout } from '../layouts/Layout';
import { SEO } from '../components/SEO';
import { UnderConstruction } from '../components/UnderConstruction';

export default function CommunityPage() {
  return (
    <Layout>
      <SEO title="Community" />
      <div className="absolute inset-0 w-full top-1/2 ">
        <UnderConstruction />
      </div>
    </Layout>
  );
}
