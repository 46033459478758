import React from 'react';
import { SectionContainer } from './SectionContainer';

export const UnderConstruction = ({ className = undefined }) => (
  <SectionContainer className={className}>
    <div className="flex flex-col items-center justify-center -mt-20">
      <h1 className="mt-2 font-bold text-3xl text-center text-white whitespace-pre-wrap sm:text-4xl">Comming Soon</h1>
      <h2 className="mt-6 text-xl text-center text-gray-400 whitespace-pre-wrap sm:text-2xl">
        공간을 준비중입니다.
        <br />
        조금만 기다려 주세요!
      </h2>
    </div>
  </SectionContainer>
);
